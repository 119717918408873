import axios from 'axios'
import { history } from 'utils/history'

export const authService = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  headers: {
    'Content-Type': 'application/json',
  },
})

authService.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error?.response?.status === 403 && error?.response.data?.isActive === false) {
      localStorage.removeItem('token')
      history.logoutUser()
      history.navigate('/login', { replace: true })
    }
    return Promise.reject(error)
  }
)
