// assets
import { ChromeOutlined, QuestionOutlined, SettingOutlined } from '@ant-design/icons';

// icons
const icons = {
  ChromeOutlined,
  QuestionOutlined,
  SettingOutlined
};

// ==============================|| MENU ITEMS - SAMPLE PAGE & DOCUMENTATION ||============================== //

const support = {
  id: 'support',
  title: 'Settings',
  type: 'collapse',
  children: [
    {
      id: 'settings',
      title: 'My Profile',
      type: 'item',
      url: '/my-profile',
      icon: icons.SettingOutlined,
      breadcrumbs: false
    },
    {
      id: 'notification-setting',
      title: 'Notifications',
      type: 'item',
      url: '/notifications-setting',
      icon: icons.SettingOutlined,
      breadcrumbs: false
    },
    {
      id: 'change-password',
      title: 'Change Password',
      type: 'item',
      url: '/change-password',
      icon: icons.SettingOutlined,
      breadcrumbs: false
    }
  ]
};

export default support;
