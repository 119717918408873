import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { authService } from 'services/authService'

export const authLoginUser = createAsyncThunk('auth/loginUser', async (payload, { rejectWithValue }) => {
  try {
    const response = await authService.post('/auth/login.php', payload)
    return response.data // Assuming the successful response's relevant data is in `data`
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      // Assuming error.response.data contains the error message you want to expose
      return rejectWithValue(error.response.data)
    }
  }
  // return response;
})

export const verifyOTP = createAsyncThunk('auth/verifyOTP', async (payload, { rejectWithValue }) => {
  try {
    const response = await authService.post('/auth/user-otp-verification.php', payload)
    return response.data // Assuming the successful response's relevant data is in `data`
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      // Assuming error.response.data contains the error message you want to expose
      return rejectWithValue(error.response.data)
    }
  }
  // return response;
})

export const resendOTP = createAsyncThunk('auth/resendOTP', async (payload, { rejectWithValue }) => {
  try {
    const response = await authService.post('/auth/resend-otp.php', payload)
    return response.data // Assuming the successful response's relevant data is in `data`
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      // Assuming error.response.data contains the error message you want to expose
      return rejectWithValue(error.response.data)
    }
  }
  // return response;
})

export const getUserDetails = createAsyncThunk('auth/getUserDetails', async (payload, { rejectWithValue }) => {
  try {
    const token = localStorage.getItem('token')
    // Include the Authorization header with the Bearer token
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const response = await authService.post('/user/user-details.php', payload, config)
    return response.data // Assuming the successful response's relevant data is in `data`
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      // Assuming error.response.data contains the error message you want to expose
      return rejectWithValue(error.response.data)
    }
  }
})

// export const authLogoutUser = createAsyncThunk('auth/logoutUser', async () => {
//   const response = await authService.get(`/auth/logout`);
//   return response;
// });

export const authUserChangePassword = createAsyncThunk('auth/changePassword', async (payload, { rejectWithValue }) => {
  try {
    const token = localStorage.getItem('token')
    // Include the Authorization header with the Bearer token
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const response = await authService.post('/auth/change-password.php', payload, config)
    return response.data // Assuming the successful response's relevant data is in `data`
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      // Assuming error.response.data contains the error message you want to expose
      return rejectWithValue(error.response.data)
    }
  }
  // return response;
})
export const authProfileUpdate = createAsyncThunk('auth/MyProfile', async (payload, { rejectWithValue }) => {
  try {
    const token = localStorage.getItem('token')
    // Include the Authorization header with the Bearer token
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const response = await authService.post('/auth/update-profile.php', payload, config)
    return response.data // Assuming the successful response's relevant data is in `data`
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      // Assuming error.response.data contains the error message you want to expose
      return rejectWithValue(error.response.data)
    }
  }
  // return response;
})

export const updatePassword = createAsyncThunk('auth/updatePassword', async (payload, { rejectWithValue }) => {
  try {

    const response = await authService.post('auth/update-password-via-otp', payload)
    return response.data // Assuming the successful response's relevant data is in `data`
  } catch (error) {

    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      // Assuming error.response.data contains the error message you want to expose
      return rejectWithValue(error.response.data)
    }
  }
  // return response;
})

export const authProfileImageUpdate = createAsyncThunk('auth/MyProfile', async (payload, { rejectWithValue }) => {
  try {
    const token = localStorage.getItem('token')

    const formData = new FormData()
    formData.append('image', payload.value)
    delete payload.name
    delete payload.value
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    }
    const response = await authService.post('/auth/update-profile.php', formData, config)
    return response.data // Assuming the successful response's relevant data is in `data`
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      // Assuming error.response.data contains the error message you want to expose
      return rejectWithValue(error.response.data)
    }
  }
  // return response;
})

export const checkUserExist = createAsyncThunk('auth/checkUserExist', async (payload, { rejectWithValue }) => {
  try {
    const response = await authService.post('/auth/check-user-email.php', payload)
    console.log("🚀 ~ checkUserExist ~ response:", response)
    return response.data // Assuming the successful response's relevant data is in `data`
  } catch (error) {
    if (error.response) {
      return rejectWithValue(error.response.data)
    }
  }
})


const initialState = {
  isAuthenticated: false,
  token: null,
  isLoading: false,
  error: null,
  otpError: null,
  resendOtp: null,
  otpMessage: null,
  key: '',
  user: null,
  isPasswordChanged: null,
  authenticateMethod: null,
  isPassword: null,
  smsResponse: null,
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginUserSuccess: (state, action) => {
      state.isAuthenticated = true
      state.isLoading = false
      state.user = action.payload?.user
    },
    updatePasswordSuccess: (state, action) => {
      state.isLoading = false
      const token = action.payload?.token
      if (token) {
        state.isAuthenticated = true
        localStorage.setItem('token', token)
      }
      state.user = action.payload?.user
      state.otpError = null
      state.isPasswordChanged = false
      state.isLoading = false
      state.isAuthenticated = true
    },

    logoutUser: (state) => {
      state.isAuthenticated = false
      state.token = null
      state.isLoading = false
      state.user = null
      state.error = null
      state.otpError = null
      state.key = ''
      localStorage.removeItem('token')
    },
    resetError: (state) => {
      state.error = null
    },
    changePasswordSuccess: (state) => {
      state.isPasswordChanged = null
    },
  },
  extraReducers: (builder) => {
    /* authLoginUser */
    builder.addCase(authLoginUser.pending, (state) => {
      state.isLoading = true
      state.isAuthenticated = false
      state.error = null
      state.otpError = null
      state.user = null
      state.resendOtp = null
    })
    builder.addCase(authLoginUser.fulfilled, (state, action) => {
      state.isLoading = false
      state.user = action.payload?.user
      state.key = action.payload?.key
      state.authenticateMethod = 'email'
      state.error = null
      state.otpError = null
      const token = action.payload?.token
      if (token) {
        state.isAuthenticated = true
        localStorage.setItem('token', token)
      }
    })
    builder.addCase(authLoginUser.rejected, (state, action) => {
      state.isLoading = false
      state.isAuthenticated = false
      state.token = null
      state.otpError = null
      state.key = action.payload?.key
      state.user = action.payload?.user
      state.error = action.payload
      state.otpMessage = action.payload?.message
      state.authenticateMethod = action.payload.code === 1002 ? 'authenticator' : action.payload.code === 1001 ? 'email' : ''
    })
    /* checkUserExist */
    builder.addCase(checkUserExist.pending, (state) => {
      state.isLoading = true
      state.error = null
      state.isPassword = null
      state.smsResponse = null
    })
    builder.addCase(checkUserExist.fulfilled, (state, action) => {
      state.isLoading = false
      state.isPassword = action.payload?.is_password
      state.smsResponse = action.payload?.sms_response
      state.error = null
    })
    builder.addCase(checkUserExist.rejected, (state, action) => {
      state.isLoading = false
      state.error = action.payload
      state.isPassword = action.payload ? action.payload?.is_password : null
      state.smsResponse = action.payload ? action.payload?.sms_response : null
      state.user = null
    })


    /* verifyOTP */
    builder.addCase(verifyOTP.pending, (state) => {
      state.isLoading = true
      state.isAuthenticated = false
      state.otpError = null
      state.error = null
    })
    builder.addCase(verifyOTP.fulfilled, (state, action) => {
      state.isLoading = false
      const token = action.payload?.token
      if (token) {
        state.isAuthenticated = true
        localStorage.setItem('token', token)
      }
      state.user = action.payload?.user
      state.otpError = null
    })
    builder.addCase(verifyOTP.rejected, (state, action) => {
      state.isLoading = false
      state.isAuthenticated = false
      state.token = null
      state.otpError = action.payload
    })

    /* resendOTP */
    builder.addCase(resendOTP.pending, (state) => {
      state.isLoading = true
      state.isAuthenticated = false
      state.otpError = null
      state.error = null
    })
    builder.addCase(resendOTP.fulfilled, (state, action) => {
      state.isLoading = false
      state.key = action.payload?.key
      state.resendOTP = true
      const token = action.payload?.token
      if (token) {
        state.isAuthenticated = true
        localStorage.setItem('token', token)
      }
      state.otpError = null
    })
    builder.addCase(resendOTP.rejected, (state, action) => {
      state.isLoading = false
      state.isAuthenticated = false
      state.token = null
      state.otpError = action.payload
    })

    /* authLogoutUser */
    builder.addCase(getUserDetails.pending, (state) => {
      state.isLoading = true
      state.error = null
    })
    builder.addCase(getUserDetails.fulfilled, (state, action) => {
      state.isLoading = false
      state.user = action.payload?.data
      state.error = null
    })
    builder.addCase(getUserDetails.rejected, (state, action) => {
      state.isLoading = false
      state.error = action.payload
    })

    /* authChangePassword */
    builder.addCase(authUserChangePassword.pending, (state) => {
      //console.log('pending:'+state);
      state.isLoading = true
      state.error = null
      state.isPasswordChanged = null
    })
    builder.addCase(authUserChangePassword.fulfilled, (state, action) => {
      //console.log('fulfilled:'+state);
      state.isLoading = false
      state.error = null
      state.isPasswordChanged = action.payload
    })
    builder.addCase(authUserChangePassword.rejected, (state, action) => {
      //console.log('rejected:'+state);
      state.isLoading = false
      state.error = action.payload
      state.isPasswordChanged = null
    })

    /* updatePassword */
    builder.addCase(updatePassword.pending, (state) => {
      state.isLoading = true
      state.error = null
      state.isPasswordChanged = null
    })
    builder.addCase(updatePassword.fulfilled, (state, action) => {
      state.isLoading = false
      state.user = action.payload?.user
      state.error = null
      state.otpError = null
      const token = action.payload?.token
      if (token) {
        state.isAuthenticated = true
        localStorage.setItem('token', token)
      }
    })

    builder.addCase(updatePassword.rejected, (state, action) => {
      state.isLoading = false
      state.isAuthenticated = false
      state.token = null
      state.otpError = action.payload
    })



    /* authProfileUpdate */
    builder.addCase(authProfileUpdate.pending, (state) => {
      state.isLoading = true
      state.error = null
    })
    builder.addCase(authProfileUpdate.fulfilled, (state, action) => {
      //console.log('fulfilled:'+state);
      state.error = null
      state.user = action.payload.data
      state.isLoading = false
    })
    builder.addCase(authProfileUpdate.rejected, (state, action) => {
      //console.log('rejected:'+state);
      state.isLoading = false
      state.error = action.payload
      state.user = null
    })
  },
})
export const { loginUserSuccess, logoutUser, resetError, changePasswordSuccess, updatePasswordSuccess } = authSlice.actions
export default authSlice.reducer
