// project import
// import NavCard from './NavCard';
import Navigation from './Navigation'
import NavFooter from './NavFooter'
import SimpleBar from 'components/third-party/SimpleBar'

// ==============================|| DRAWER CONTENT ||============================== //

const DrawerContent = () => (
  <SimpleBar
    sx={{
      height: '100%',
      '& .simplebar-content': {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      },
    }}
  >
    <Navigation />
    <NavFooter />
    {/* <NavCard /> */}
  </SimpleBar>
)

export default DrawerContent
