import React from 'react'
import { useDispatch } from 'react-redux'
// Assuming you have a logout function that clears the user's auth data
import { logoutUser } from 'store/reducers/auth'
import { persistor } from 'store'

const Logout = () => {
  const dispatch = useDispatch()

  React.useEffect(() => {
    // Call your logout function
    persistor.purge()
    dispatch(logoutUser())

    // Redirect the user to the login page or home page after logging out
    window.location = '/login'
    // navigate('/login', { replace: true });
  }, [])

  // Render nothing or a spinner while the logout process completes
  return null
}

export default Logout
