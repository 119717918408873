import { useEffect, useRef, useState } from 'react'
import React from 'react'

import { useLazyGetNotificationsQuery, useMarkNotificationAsReadMutation } from 'services/apiService'
import moment from 'moment'
// material-ui
import { useTheme } from '@mui/material/styles'
import {
  Avatar,
  Badge,
  Box,
  ClickAwayListener,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Paper,
  Popper,
  Typography,
  useMediaQuery,
} from '@mui/material'

// project import
import MainCard from 'components/MainCard'
import Transitions from 'components/@extended/Transitions'
import { useNavigate } from 'react-router-dom'

// assets
import { BellOutlined, CloseOutlined, MessageOutlined } from '@ant-design/icons'
import { notificationTypes } from 'constants/notificationTypes'

// sx styles
const avatarSX = {
  width: 36,
  height: 36,
  fontSize: '1rem',
}

const actionSX = {
  mt: '6px',
  ml: 1,
  top: 'auto',
  right: 'auto',
  alignSelf: 'flex-start',

  transform: 'none',
}

// ==============================|| HEADER CONTENT - NOTIFICATION ||============================== //

const Notification = () => {
  const [getNotifications, { data }] = useLazyGetNotificationsQuery()
  const [markNotificationAsRead] = useMarkNotificationAsReadMutation()
  const navigate = useNavigate()

  useEffect(() => {
    getNotifications()
  }, [])
  const notifications = data?.data
  const unread_count = data?.unread_count

  const theme = useTheme()
  const matchesXs = useMediaQuery(theme.breakpoints.down('md'))

  const anchorRef = useRef(null)
  const [open, setOpen] = useState(false)
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }

  const handleNotificationClick = async (notification) => {
    setOpen(false)
    await markNotificationAsRead({ notification_id: notification.id })
    await getNotifications()
    navigate(notificationTypes[notification?.data?.type]?.url || '/', { replace: true })
  }

  const iconBackColorOpen = 'grey.300'
  const iconBackColor = 'grey.100'
  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <IconButton
        disableRipple
        color="secondary"
        sx={{ color: 'text.primary', bgcolor: open ? iconBackColorOpen : iconBackColor }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? 'profile-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        {unread_count > 0 ? (
          <Badge badgeContent={unread_count} color="primary">
            <BellOutlined />
          </Badge>
        ) : (
          <Badge color="primary">
            <BellOutlined />
          </Badge>
        )}
      </IconButton>
      <Popper
        placement={matchesXs ? 'bottom' : 'bottom-end'}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [matchesXs ? -5 : 0, 9],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={open} {...TransitionProps}>
            <Paper
              sx={{
                boxShadow: theme.customShadows.z1,
                width: '100%',
                minWidth: 285,
                maxWidth: 420,
                [theme.breakpoints.down('md')]: {
                  maxWidth: 285,
                },
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  title="Notification"
                  elevation={0}
                  border={false}
                  content={false}
                  secondary={
                    <IconButton size="small" onClick={handleToggle}>
                      <CloseOutlined />
                    </IconButton>
                  }
                >
                  <List
                    component="nav"
                    sx={{
                      p: 0,
                      '& .MuiListItemButton-root': {
                        py: 0.5,
                        '& .MuiAvatar-root': avatarSX,
                        '& .MuiListItemSecondaryAction-root': { ...actionSX, position: 'relative' },
                      },
                      maxHeight: '70vh',
                      overflow: 'scroll',
                    }}
                  >
                    {notifications.map((notification) => (
                      <React.Fragment key={notification.id}>
                        <ListItemButton
                          onClick={() => handleNotificationClick(notification)}
                          sx={{
                            backgroundColor: Number(notification.is_read) ? '#fff' : 'grey.300',
                          }}
                        >
                          <ListItemAvatar>
                            <Avatar
                              sx={{
                                color: 'primary.main',
                                bgcolor: 'primary.lighter',
                              }}
                            >
                              <MessageOutlined />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary={<Typography variant="h6">{notification.info_text}</Typography>}
                            secondary={moment(notification.createdAt).format('D MMMM')}
                          />
                          <ListItemSecondaryAction>
                            <Typography variant="caption" noWrap>
                              {moment(notification.createdAt).format('hh:mm a')}
                            </Typography>
                          </ListItemSecondaryAction>
                        </ListItemButton>
                        <Divider />
                      </React.Fragment>
                    ))}
                    <ListItemButton sx={{ textAlign: 'center', py: `${12}px !important` }}>
                      <ListItemText
                        primary={
                          <Typography
                            variant="h6"
                            color="primary"
                            onClick={() => {
                              setOpen(false)
                              navigate('/notifications')
                            }}
                          >
                            View All
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  </List>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </Box>
  )
}

export default Notification
