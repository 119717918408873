// assets
import { AppstoreOutlined, HistoryOutlined } from '@ant-design/icons'

// icons
const icons = {
  AppstoreOutlined,
  HistoryOutlined,
}

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const dashboard = {
  id: 'group-dashboard',
  title: 'Navigation',
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: 'Dashboard',
      type: 'item',
      url: '/dashboard',
      icon: icons.AppstoreOutlined,
      breadcrumbs: false,
    },
    {
      id: 'transaction',
      title: 'Transaction History',
      type: 'item',
      url: '/transaction',
      icon: icons.HistoryOutlined,
      breadcrumbs: false,
    },
    {
      id: 'invoices',
      title: 'Invoices',
      type: 'item',
      url: '/invoices',
      icon: icons.HistoryOutlined,
      breadcrumbs: false,
    },
  ],
}

export default dashboard
