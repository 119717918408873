import React from 'react'
import { useRoutes } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { getUserDetails } from 'store/reducers/auth'

// project import
import LoginRoutes from './LoginRoutes'
import MainRoutes from './MainRoutes'

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  const dispatch = useDispatch()
  React.useEffect(() => {
    if (localStorage.getItem('token')) {
      dispatch(getUserDetails())
    }
  }, [dispatch])
  return useRoutes([MainRoutes, LoginRoutes])
}
