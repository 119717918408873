// project import
import Routes from 'routes'
import ThemeCustomization from 'themes'
import ScrollTop from 'components/ScrollTop'
import { Toaster } from 'react-hot-toast'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { logoutUser } from 'store/reducers/auth'

import { history } from 'utils/history'
import { persistor } from 'store'
import React from 'react'

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const App = () => {
  const dispatch = useDispatch()
  history.navigate = useNavigate()
  history.location = useLocation()
  const location = useLocation()
  const userEmail = useSelector((state) => state.auth.user?.email_address)
  console.log("deployed==>")

  const query = new URLSearchParams(location.search)
  const emailFromQuery = query.get('email')

  const logoutUserNow = () => {
    persistor.purge()
    dispatch(logoutUser())
  }

  history.logoutUser = logoutUserNow

  React.useEffect(() => {
    if (emailFromQuery && userEmail && emailFromQuery !== userEmail) {
      logoutUserNow()
    }
  }, [emailFromQuery, userEmail])

  return (
    <ThemeCustomization>
      <ScrollTop>
        <Toaster />
        <Routes />
      </ScrollTop>
    </ThemeCustomization>
  )
}

export default App