// third-party
import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

// project import
import { apiService } from 'services/apiService';
import menuReducer from './reducers/menu';
import authReducer from './reducers/auth';

// Persist configuration for auth reducer
const authPersistConfig = {
  key: 'auth',
  storage,
  // Add more configuration options here if needed
};

const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  menu: menuReducer,
  [apiService.reducerPath]: apiService.reducer
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    }).concat(apiService.middleware)
});

const persistor = persistStore(store);

const { dispatch } = store;

export { store, dispatch, persistor };
