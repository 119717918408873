// third-party
import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

// project import
import { apiService } from 'services/apiService'
import menuReducer from './reducers/menu'
import authReducer from './reducers/auth'

// Clear localStorage if on login page
if (window.location.pathname === '/login') {
  localStorage.clear()
}

// Persist configuration for auth reducer
const authPersistConfig = {
  key: 'auth',
  storage,
  version: 1,
  whitelist: ['user', 'token', 'isAuthenticated'], // added isAuthenticated
  blacklist: ['error', 'loading', 'otpError']
}

const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  menu: menuReducer,
  [apiService.reducerPath]: apiService.reducer,
})

// Create store with error handling
let store
try {
  store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }).concat(apiService.middleware),
  })
} catch (error) {
  console.error('Failed to create store:', error)
  throw error
}

// Create persistor with error handling
let persistor
try {
  persistor = persistStore(store)
} catch (error) {
  console.error('Failed to create persistor:', error)
  throw error
}

const { dispatch } = store

// Function to purge persisted state and clear localStorage
export const purgeStore = async () => {
  localStorage.clear()
  await persistor.purge()
  await persistor.flush()
}

export { store, dispatch, persistor }