// project import
// import pages from './pages';
import dashboard from './dashboard'
// import utilities from './utilities';
import support from './support'
import footer from './footer'

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [dashboard, support],
  footer,
}

export default menuItems
