import PropTypes from 'prop-types'
import { useRef } from 'react'

// material-ui
import { Avatar, Box, ButtonBase, Typography, Stack } from '@mui/material'

// project import
import { useSelector } from 'react-redux'

// tab panel wrapper
function TabPanel({ children, value, index, ...other }) {
  return (
    <div role="tabpanel" hidden={value !== index} id={`profile-tabpanel-${index}`} aria-labelledby={`profile-tab-${index}`} {...other}>
      {value === index && children}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

// ==============================|| HEADER CONTENT - PROFILE ||============================== //

const Profile = () => {
  const { user } = useSelector((state) => state.auth)

  const anchorRef = useRef(null)
  const open = false

  const iconBackColorOpen = 'grey.300'

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <ButtonBase
        sx={{
          p: 0.25,
          bgcolor: open ? iconBackColorOpen : 'transparent',
          borderRadius: 1,
          '&:hover': { bgcolor: 'secondary.lighter' },
        }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? 'profile-grow' : undefined}
        aria-haspopup="true"
      >
        <Stack direction="row" spacing={2} alignItems="center" sx={{ p: 0.5 }}>
          <Avatar alt={user?.first_name} src={user?.details?.image_name} sx={{ width: 32, height: 32 }}>
            {user?.details?.image_name ? '' : user?.first_name?.charAt(0)}
          </Avatar>
          <Typography variant="subtitle1">
            {user?.first_name} {user?.last_name}
          </Typography>
        </Stack>
      </ButtonBase>
    </Box>
  )
}

export default Profile
