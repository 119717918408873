import { List } from '@mui/material';
import NavItem from './NavItem';

// ==============================|| NAVIGATION GROUP ||============================== //

const NavGroupCollapseItem = ({ item }) => {
    const navCollapse = item.children?.map((menuItem) => {
        switch (menuItem.type) {
          case 'collapse':
            return (
              <Typography key={menuItem.id} variant="caption" color="error" sx={{ p: 2.5 }}>
                collapse - only available in paid version
              </Typography>
            );
          case 'item':
            return <NavItem key={menuItem.id} item={menuItem} level={1} />;
          default:
            return (
              <Typography key={menuItem.id} variant="h6" color="error" align="center">
                Fix - Group Collapse or Items
              </Typography>
            );
        }
      });

  return (
    <List component="nav" disablePadding>
      {navCollapse}
    </List>
  );
};

export default NavGroupCollapseItem;
