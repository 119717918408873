import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_API_URL}`,
  prepareHeaders: (headers) => {
    const token = localStorage.getItem('token');
    headers.set('Content-Type','application/json')
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  }
});

export const apiService = createApi({
  reducerPath: 'api',
  baseQuery,
  tagTypes: ['Post', 'Group', 'Vps','Notifications'],
  endpoints: (builder) => ({
    //User endpoints
    getUserDetails: builder.query({
      query: (body) => ({
        url: `/user/user-details.php`,
        method: 'POST'
      })
    }),

    //Transaction endpoints
    getTransactions: builder.query({
      query: () => ({
        url: `/transaction/get-transactions.php`
      })
    }),

    //Invoices endpoints
    getInvoices: builder.query({
      query: () => ({
        url: `/invoice/get-invoices.php`
      })
    }),
    payInvoice: builder.mutation({
      query: (body) => ({
        url: `/invoice/pay`,
        method: 'POST',
        body
      })
    }),
    rejectInvoice: builder.mutation({
      query: (body) => ({
        url: `/invoice/reject`,
        method: 'POST',
        body
      })
    }),
    getTwoFaList: builder.query({
      query: () => ({
        url: `/auth/get-2fa.php`
      })
    }),
    getLoginHistory: builder.query({
      query: () => ({
        url: `/fa/login-history.php`
      })
    }),

    getAuthSecret: builder.query({
      query: () => ({
        url: `/auth/add-2fa.php`
      })
    }),

    getListTwoFa: builder.query({
      query: () => ({
        url: `/auth/get-2fa.php`
      })
    }),
    verifyTwoFa: builder.mutation({
      query: (body) => ({
        url: `/auth/verify-2fa.php`,
        method: 'POST',
        body,
      })
    }),
    deleteTwoFa: builder.mutation({
      query: (body) => ({
        url: `/auth/delete-2fa.php`,
        method: 'POST',
        body
      })
    }),
    getNotifications: builder.query({
      query: (params) => {
        return {
          url: `/user/get-notifications.php?status=${params?.status || 'any'}&&limit=${params?.limit || 10}&&page=${params?.page || 1}`
        }
      },
      providesTags: (result, error, arg) => [{ type: 'Notifications' }]
    }),
    markNotificationAsRead: builder.mutation({
      query: (body) => ({
        url: `/user/read-notifications.php`,
        method: 'POST',
        body
      }),
      invalidatesTags: [{ type: 'Notifications' }]
    }),
    changePassword: builder.mutation({
      query: (body) => ({
        url: `/auth/change-password.php`,
        method: 'POST',
        body
      }),
      invalidatesTags: [{ type: 'Notifications' }]
    }),
  })
});

export const { useGetUserDetailsQuery, useGetTransactionsQuery, useGetInvoicesQuery, usePayInvoiceMutation, useRejectInvoiceMutation, useLazyGetNotificationsQuery,useGetTwoFaListQuery,useGetLoginHistoryQuery, useGetAuthSecretQuery, useGetListTwoFaQuery,useVerifyTwoFaMutation,useDeleteTwoFaMutation,useMarkNotificationAsReadMutation,
  useChangePasswordMutation
} =
apiService;
