export const notificationTypes = {
  password_change: {
    url: '/settings',
  },
  create_invoice: {
    url: '/invoices',
  },
  pay_invoice: {
    url: '/invoices',
  },
  reject_invoice: {
    url: '/invoices',
  },
  cancel_invoice: {
    url: '/invoices',
  },
}
