import { useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

// material-ui
import { Box, List, Typography, Collapse, IconButton } from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material'

// project import
import NavGroup from './NavGroup'
import NavGroupCollapseItem from './NavGroupCollapseItem'

// ==============================|| NAVIGATIONGroupCollapse - LIST GROUP ||============================== //

const NavGroupCollapse = ({ item }) => {
  const [openGroups, setOpenGroups] = useState({ support: true, otherGroup: false })
  const handleGroupClick = (groupId) => {
    setOpenGroups((prevState) => ({ ...prevState, [groupId]: !prevState[groupId] }))
  }
  const menu = useSelector((state) => state.menu)
  const { drawerOpen } = menu

  const navCollapse = () => {
    switch (item.type) {
      case 'collapse':
        return (
          <Box key={item.id}>
            <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ pl: 3, mb: 1.5 }}>
              <Typography variant="h6" onClick={() => handleGroupClick(item.id)} style={{ cursor: 'pointer' }}>
                {item.title}
              </Typography>
              <IconButton color="secondary" onClick={() => handleGroupClick(item.id)} size="small">
                {openGroups[item.id] ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            </Box>
            <Collapse in={openGroups[item.id]} timeout="auto" unmountOnExit>
              <NavGroupCollapseItem item={item} />
            </Collapse>
          </Box>
        )
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Fix - Group Collapse or Items
          </Typography>
        )
    }
  }

  return <List sx={{ mb: drawerOpen ? 1.5 : 0, py: 0, zIndex: 0 }}>{navCollapse()}</List>
}

NavGroup.propTypes = {
  item: PropTypes.object,
}

export default NavGroupCollapse
