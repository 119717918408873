import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { history } from 'utils/history'
import { toast } from 'react-hot-toast'

const baseQuery = async (args, api, extraOptions) => {
  const rawBaseQuery = fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token')
      headers.set('Content-Type', 'application/json')
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      return headers
    },
  })
  const result = await rawBaseQuery(args, api, extraOptions)
  if (
    // (result?.error && result?.error?.status === 401 && result?.error?.data?.message === 'No Bearer Token') ||
    (result?.error && result?.error?.status === 403 && !result?.error?.data?.isActive)
  ) {
    toast.error('Session expired. Please login again.')
    if (window.location.pathname !== '/login') {
      localStorage.removeItem('token')
      await history.logoutUser()
      await history.navigate('/login', { replace: true })
    }
  }
  return result
}

export const apiService = createApi({
  reducerPath: 'api',
  baseQuery,
  tagTypes: ['Post', 'Group', 'Vps', 'Notifications'],
  endpoints: (builder) => ({
    //User endpoints
    getUserDetails: builder.query({
      query: () => ({
        url: '/user/user-details.php',
        method: 'POST',
      }),
    }),
    updateNotificationStatus: builder.mutation({
      query: (body) => ({
        url: '/user/set-marketing-notification-status.php',
        method: 'PUT',
        body,
      }),
    }),

    updateOtpPreference: builder.mutation({
      query: (body) => ({
        url: '/user/set-otp-preference.php',
        method: 'PUT',
        body,
      }),
    }),


    //Transaction endpoints
    getTransactions: builder.query({
      query: () => ({
        url: '/transaction/get-transactions.php',
      }),
    }),

    //Invoices endpoints
    getInvoices: builder.query({
      query: () => ({
        url: '/invoice/get-invoices.php',
      }),
    }),
    payInvoice: builder.mutation({
      query: (body) => ({
        url: '/invoice/pay',
        method: 'POST',
        body,
      }),
    }),
    rejectInvoice: builder.mutation({
      query: (body) => ({
        url: '/invoice/reject',
        method: 'POST',
        body,
      }),
    }),
    getTwoFaList: builder.query({
      query: () => ({
        url: '/auth/get-2fa.php',
      }),
    }),
    getLoginHistory: builder.query({
      query: () => ({
        url: '/fa/login-history.php',
      }),
    }),

    getAuthSecret: builder.query({
      query: () => ({
        url: '/auth/add-2fa.php',
      }),
    }),

    getListTwoFa: builder.query({
      query: () => ({
        url: '/auth/get-2fa.php',
      }),
    }),
    verifyTwoFa: builder.mutation({
      query: (body) => ({
        url: '/auth/verify-2fa.php',
        method: 'POST',
        body,
      }),
    }),
    deleteTwoFa: builder.mutation({
      query: (body) => ({
        url: '/auth/delete-2fa.php',
        method: 'POST',
        body,
      }),
    }),
    getNotifications: builder.query({
      query: (params) => {
        return {
          url: `/user/get-notifications.php?status=${params?.status || 'any'}&&limit=${params?.limit || 10}&&page=${params?.page || 1}`,
        }
      },
      providesTags: () => [{ type: 'Notifications' }],
    }),
    markNotificationAsRead: builder.mutation({
      query: (body) => ({
        url: '/user/read-notifications.php',
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'Notifications' }],
    }),
    changePassword: builder.mutation({
      query: (body) => ({
        url: '/auth/change-password.php',
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'Notifications' }],
    }),
  }),
})

export const {
  useGetUserDetailsQuery,
  useUpdateNotificationStatusMutation,
  useUpdateOtpPreferenceMutation,
  useGetTransactionsQuery,
  useGetInvoicesQuery,
  usePayInvoiceMutation,
  useRejectInvoiceMutation,
  useLazyGetNotificationsQuery,
  useGetTwoFaListQuery,
  useGetLoginHistoryQuery,
  useGetAuthSecretQuery,
  useGetListTwoFaQuery,
  useVerifyTwoFaMutation,
  useDeleteTwoFaMutation,
  useMarkNotificationAsReadMutation,
  useChangePasswordMutation,
} = apiService
