// material-ui
// project import
import NavGroup from '../Navigation/NavGroup'
import menuItem from 'menu-items'

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const NavFooter = () => {
  return <NavGroup key={menuItem.footer.id} item={menuItem.footer} />
}

export default NavFooter
