import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';
import AuthProvider from './AuthProvider';
import Logout from 'pages/authentication/Logout';
// render - dashboard
const DashboardDefault = Loadable(lazy(() => import('pages/dashboard')));

const Settings = Loadable(lazy(() => import('pages/settings')));
const Transaction = Loadable(lazy(() => import('pages/transaction')));
const Invoices = Loadable(lazy(() => import('pages/invoices')));
const Notifications = Loadable(lazy(() => import('pages/notifications')));
const MyProfile = Loadable(lazy(() => import('pages/my-profile')));
const NotificationsSetting = Loadable(lazy(() => import('pages/notifications-setting')));
const ChangePassword = Loadable(lazy(() => import('pages/change-password')));
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: (
    <AuthProvider>
      <MainLayout />
    </AuthProvider>
  ),
  children: [
    {
      path: '/',
      element: <Navigate to="dashboard" replace />
    },
    {
      path: 'dashboard',
      element: <DashboardDefault />
    },
    {
      path: 'transaction',
      element: <Transaction />
    },
    {
      path: 'invoices',
      element: <Invoices />
    },
    {
      path: 'help',
      element: <Settings />
    },
    {
      path: 'contact',
      element: <Settings />
    },
    {
      path: 'notifications',
      element: <Notifications />
    },
    {
      path: 'logout',
      element: <Logout />
    },
    {
      path: 'my-profile',
      element: <MyProfile />
    },
    {
      path: 'notifications-setting',
      element: <NotificationsSetting />
    },
    {
      path: 'change-password',
      element: <ChangePassword />
    }
  ]
};

export default MainRoutes;
