// assets
import { LoginOutlined } from '@ant-design/icons';

// icons
const icons = {
  LoginOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const footer = {
  id: 'footer',
  title: '',
  type: 'group',
  children: [
    // {
    //   id: 'help',
    //   title: 'Help',
    //   type: 'item',
    //   url: '/help',
    //   breadcrumbs: false
    // },
    // {
    //   id: 'contact',
    //   title: 'Contact us',
    //   type: 'item',
    //   url: '/contact',
    //   breadcrumbs: false
    // },
    {
      id: 'logout',
      title: 'Log out',
      type: 'item',
      url: '/logout',
      icon: icons.LoginOutlined,
      breadcrumbs: false
    }
  ]
};

export default footer;
